import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "V2.0/common/table";
import * as knowledgebaseService from "V2.0/services/knowledgebaseService";
import { useHistory, useLocation } from "react-router";
import { message } from "antd";
import { Switch } from "V2.0/common/switch";
import { Subheading } from "V2.0/common/heading";
import { Button } from "V2.0/common/button";
import success from "V2.0/animation/succesful.json";

function AddURLData({ activeProject, projects, onClose, refetch }) {
  const [formState, setFormState] = useState("empty");
  const [checkedUrls, setCheckedUrls] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [urlSuggestions, setURLSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const container = useRef(null);

  useEffect(() => {
    if (formState === "success") {
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success,
      });
    }
  }, [formState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    message.success("Please wait. We are fetching URLs.");

    const obj = {
      link: inputValue,
      linkLimit: 200,
    };

    try {
      setLoading(true);
      const response = await knowledgebaseService.fetchURLs(obj);
      setURLSuggestions(response.data);
      setFormState("filled");
      if (response.data.length < 1) {
        message.error(
          "Scrawling may not be enabled on this page. We were unable to fetch any URLs from here."
        );
        setFormState("success");
      }
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
      }
    }
  };

  const handleURLSubmit = async () => {
    const urlList = Object.entries(checkedUrls)
      .filter(([url, isChecked]) => isChecked)
      .map(([url, isChecked]) => url);

    if (urlList.length < 1)
      return message.info("Please select at least one URL");

    const data = {
      urlList: urlList,
    };

    setLoading(true);

    try {
      const response = await knowledgebaseService.trainURLsForPortal(
        projects[activeProject]._id,
        data
      );
      if (response.status === 200) {
        setFormState("success");
        refetch(projects[activeProject]._id);
        message.success("URLs successfully added");
        onClose();
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        onClose();
        return;
      }
    }
  };

  function handleUrlChecked(url) {
    setCheckedUrls((prevState) => ({ ...prevState, [url]: !prevState[url] }));
  }

  const handleSelectAll = () => {
    const allUrlsChecked = urlSuggestions.reduce((acc, url) => {
      acc[url] = true;
      return acc;
    }, {});
    setCheckedUrls(allUrlsChecked);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label
          htmlFor="search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div>
          {formState === "empty" && (
            <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden shadow-sm">
              <input
                type="text"
                placeholder="Insert the link"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)} // Update state on input change
                className="flex-grow px-4 py-2 focus:outline-none border-none"
              />
              <button
                type="submit"
                className="text-black hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium text-sm px-4 py-2 rounded-none"
              >
                Submit
              </button>
            </div>
          )}

          {formState === "filled" && (
            <div className="flex justify-between items-center">
              <Subheading>
                Please add URLs you want to extract data from
              </Subheading>
              <div>
                <Button
                  className="mr-2"
                  outline
                  type="button"
                  onClick={handleSelectAll}
                >
                  Select all URLs
                </Button>
                <Button color="white" type="button" onClick={handleURLSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>

      {formState === "filled" && (
        <div className="max-h-[70dvh] overflow-scroll">
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>URLs found</TableHeader>
                <TableHeader className="text-right">
                  Confirm for extraction
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {urlSuggestions.length > 0 &&
                urlSuggestions.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium truncate max-w-xs">
                      <div className="truncate">{item}</div>
                    </TableCell>
                    <TableCell className="text-right">
                      <Switch
                        color="emerald"
                        id={`checkbox-${index}`}
                        checked={checkedUrls[item] || false}
                        onChange={() => handleUrlChecked(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}

      {formState === "success" && (
        <div>
          <div style={{ height: "400px" }} ref={container}></div>
        </div>
      )}
    </div>
  );
}

export default AddURLData;
