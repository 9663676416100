import { useState, useEffect } from "react";

import Form from "../../../components/Form";
import Joi from "joi-browser";
import { message } from "antd";
import { getTemplates } from "V2.0/services/whatsappService";

export function Whatsapp(props) {
  const { addUserToCampaign, onClose, projectId } = props;
  const [loading, setLoading] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);

  const [inputField] = useState({
    toNumber: "",
    fromNumber: "",
    triggerType: "",
    campaignType: "Whatsapp",
    template: JSON.stringify({
      templateName: "",
      templateLanguage: "",
    }),
  });

  const [errorMessage] = useState({});

  const fetchWhatsappMessageTemplates = async () => {
    try {
      const response = await getTemplates(projectId);
      const data = response.data;
      const templateNames = data.map((template) => ({
        templateName: template.name,
        templateLanguage: template.language,
      }));
      setMessageTemplates(templateNames);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWhatsappMessageTemplates();
  }, []);

  const template = [
    {
      name: "toNumber",
      label: "To Number",
      description: "Please pre-fix country code to the number",
      type: "text",
    },
    {
      name: "template",
      label: "Message Template",
      description: "Select one of the pre approved templates",
      type: "select",
      options: [{ id: 0, value: JSON.stringify({}), label: "(No template selected)" }].concat(
        messageTemplates.map((messageTemplate, index) => ({
          id: index,
          value: JSON.stringify(messageTemplate),
          label: messageTemplate.templateName,
        }))
      ),
    },

    {
      name: "fromNumber",
      label: "From Number",
      description: "Leave blank to use default number",
      type: "text",
    },

    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
  ];

  const schema = {
    toNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("To Number"),
    template: Joi.object({
      templateName: Joi.string().required(),
      templateLanguage: Joi.string().required(),
    })
      .required()
      .label("Message Template"),
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .allow(null, "")
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("campaignType"),
  };

  function onAddUserToCampaign(data) {
    const { template, ...rest } = data;
    const { templateName, templateLanguage} = JSON.parse(template);
    const updatedData = {
      ...rest,
      templateName,
      templateLanguage
    }

    addUserToCampaign(
      updatedData,
      (response) => {
        if (response.status === 200) {
          message.success("Success");
          setLoading(false);
          onClose();
        }
      },
      (error) => {
        message.error(
          error?.response?.data?.msg || "Failed to add contact"
        );
        setLoading(false);
        onClose();
        console.error(error);
      }
    )
  }

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={{
        style: "",
        buttons: "2",
        primaryLabel: "Add",
        secondaryLabel: "Close",
      }}
      onSubmit={onAddUserToCampaign}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
