import { useState } from "react";
import { addWhatsAppPhoneNumber } from "V2.0/services/whatsappService";

export function useWhatsappAddPhoneNumber() {
  const [state, setState] = useState({
    loading: false,
  });

  const addPhoneNumber = async ({
    projectId,
    data,
    onSuccess,
    onError,
    onInit,
  }) => {
    setState({ loading: true });
    onInit();
    try {
      const response = await addWhatsAppPhoneNumber(projectId, data);
      if (response.status === 200) {
        setState({ loading: false });
        onSuccess();
      }
    } catch (error) {
      setState({ loading: false });
      onError();
    }
  };

  return {
    state,
    addPhoneNumber,
  };
}
