import { Fragment, useState, useEffect, useContext } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import * as projectService from "../services/projectService";
import { useWebcall, WebcallContext } from "../Context/WebcallContext";
import HomeBilling from "./HomeBilling";
import authService from "../services/authService";
import { SidebarLayout } from "V2.0/common/sidebarLayout";
import { SidebarNavigation } from "./Home/Sidebar";
import { useSearchParams } from "react-router-dom";
import { CreateNewWorkspace } from "./Workspaces/Create_new";
import { manageSubscription } from "V2.0/service-worker/registerAndSubscribe";
import { getSettings } from "../services/projectService";

function useSettings(projectId) {
  const [settings, setSettings] = useState({});

  const fetchSettings = async () => {
    try {
      const response = await getSettings(projectId);
      const data = response.data;
      setSettings(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchSettings(projectId);
    }
  }, [projectId]);

  return {
    settings,
    fetchSettings,
  };
}

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [companyData, setCompanyData] = useState("");
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(undefined);
  const [projectId, setProjectId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cleanupResources } = useContext(WebcallContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {settings, fetchSettings} = useSettings(projectId);
  const [reload, setReload] = useState(false);

  const location = useLocation();

  const user = authService.getCurrentUser();
  const userId = user.customerId;

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await projectService.getAllProjectsForCustomerId();
      if (response.data.length < 1) {
        setIsOpen(true);
        return;
      }

      if (response.status === 200) {
        setProjects(response.data);
        setCompanyData(response.data[0].company);

        // Update navigation based on companyData.showBilling
        // if (response.data[0].company.showBilling) {
        //   updatedNavigation.push({
        //     name: "Billing",
        //     href: "#",
        //     icon: BanknotesIcon,
        //     current: false,
        //   });
        // }
        setLoading(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        return;
      }
      if (ex.response && ex.response.status === 400) {
        return;
      }
    }
  };

  const updateActiveProject = (projectIndex) => {
    localStorage.setItem("activeProject", projectIndex);
    setActiveProject(projectIndex);

    const projectId = projects[projectIndex]?._id;
    setProjectId(projectId);
  };

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [location.pathname]);

  useEffect(() => {
    if (projectId) {
      setSearchParams({ projectId }, { replace: true });
    }
  }, [projectId, location.pathname]);

  // Load the last known value of activeProject from localStorage on component mount
  useEffect(() => {
    const isProjectsAvailable = projects.length > 0;

    if (!isProjectsAvailable) {
      return;
    }

    const activeProjectIndex = localStorage.getItem("activeProject");
    const projectIdFromSearchParam = searchParams.get("projectId");
    const isActiveProjectIndexEmpty = activeProjectIndex === null;

    if (projectIdFromSearchParam) {
      const projectIndex = projects.findIndex(
        (project) => project._id === projectIdFromSearchParam
      );
      if (projectIndex != -1) {
        updateActiveProject(projectIndex);
      }

      return;
    }

    if (isActiveProjectIndexEmpty) {
      updateActiveProject(0);
      return;
    }

    const isActiveProjectIndexExistsInAvailableProjects =
      projects.length > Number(activeProjectIndex);

    if (isActiveProjectIndexExistsInAvailableProjects) {
      updateActiveProject(activeProjectIndex);
    } else {
      updateActiveProject(0);
    }
  }, [projects]);

  useEffect(() => {
    if (!projectId || !user) {
      return;
    }

    manageSubscription(projectId, userId);
  }, [projectId, userId]);

  useEffect(() => {
    fetchProjects();
  }, [refresh]);

  return (
    <>
      <SidebarLayout
        sidebar={
          <SidebarNavigation
            setIsOpen={setIsOpen}
            company={companyData}
            projects={projects}
            activeProject={activeProject}
            setActiveProject={updateActiveProject}
            firstNameShort={""}
            user={user}
            loading={loading}
          />
        }
      >
        <Outlet
          context={{
            projects,
            activeProject,
            projectId,
            searchParams,
            setSearchParams,
            settings,
            fetchSettings,
            reload, 
            setReload
          }}
        />
      </SidebarLayout>
      {isOpen && (
        <CreateNewWorkspace
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          projects={projects}
          company={companyData}
          fetchProjects={fetchProjects}
        />
      )}
      {/* {sideBarSelection === "Billing" && (
            <HomeBilling
              projects={projects}
              activeProject={activeProject}
              company={companyData}
              user={user}
            />
          )} */}
    </>
  );
}
