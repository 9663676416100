import { useState, useEffect } from "react";

import Form from "V2.0/components/Form";
import { getAllAgents } from "V2.0/services/humanAgentService";

import {
  Dialog,
  DialogBody,
  DialogTitle,
  DialogDescription,
} from "V2.0/common/dialog";
import Joi from "joi-browser";
import httpService from "V2.0/services/httpService";
import { message } from "antd";
import { changeHumanAgent } from "V2.0/services/conversationService";

export const AssignmentMode = {
  ASSIGN: "ASSIGN",
  RE_ASSIGN: "RE_ASSIGN",
};

const AgentAssignComponent = {
  [AssignmentMode.ASSIGN]: (props) => <AgentAssignment {...props} />,
  [AssignmentMode.RE_ASSIGN]: (props) => <AgentReassignment {...props} />,
};

const defaultOption = [{ id: 0, label: "(No agent selected)" }];

const getTemplate = (humanAgents) => [
  {
    name: "humanAgentId",
    label: "Human Agent",
    description: "Select the human agent to which you want to reassign to",
    type: "select",
    options: defaultOption.concat(
      humanAgents.map((agent) => ({
        id: agent._id,
        value: agent._id,
        label: `${agent.name} (${agent.email})`,
      }))
    ),
  },
];

const schema = {
  humanAgentId: Joi.string().required().label("Human Agent"),
};

function useWhatsAppAgents(projectId) {
  const [whatsappAgents, setWhatsappAgents] = useState([]);

  const fetchHumanAgents = async (projectId) => {
    try {
      const response = await getAllAgents(projectId);
      const data = response.data;
      const filteredWhatsappAgents = data.humanAgents.filter(
        (agent) => agent.contactMedium === "whatsapp"
      );
      setWhatsappAgents(filteredWhatsappAgents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchHumanAgents(projectId);
    }
  }, [projectId]);

  return {
    whatsappAgents,
    fetchWhatsappAgents: fetchHumanAgents
  };
}

export function AgentAssign(props) {
  const { mode, ...restProps } = props;
  return AgentAssignComponent[mode]({ ...restProps });
}

function AgentAssignment(props) {
  const {
    isOpen,
    onClose,
    projectId,
    conversationId,
    fetchConversations,
    agentId,
    resetSelectedConversation,
  } = props;

  const { whatsappAgents } = useWhatsAppAgents(projectId);

  const onSubmit = async (data) => {
    try {
      message.info("Assigning human");
      const response = await changeHumanAgent(data, projectId, conversationId);
      if (response.status === 200) {
        resetSelectedConversation();
        fetchConversations(1, 50);
        message.success("Assigned");
        onClose();
      }
    } catch (error) {
      console.error(error);
      message.error("Assign failed");
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Assign Human Agent</DialogTitle>
      <DialogBody>
        <AgentAssignmentForm
          agents={whatsappAgents}
          onSubmit={onSubmit}
          buttons={{
            buttons: "2",
            primaryLabel: "Assign",
            secondaryLabel: "Cancel",
          }}
          onClose={onClose}
        />
      </DialogBody>
    </Dialog>
  );
}

function AgentReassignment(props) {
  const {
    isOpen,
    onClose,
    projectId,
    conversationId,
    fetchConversations,
    agentId,
    resetSelectedConversation,
  } = props;
  const { whatsappAgents } = useWhatsAppAgents(projectId);

  const agentsExcludingActiveAgent = whatsappAgents.filter(
    (agent) => agent._id !== agentId
  );

  const onSubmit = async (data) => {
    try {
      message.info("Reassigning conversation");
      const response = await changeHumanAgent(data, projectId, conversationId);
      if (response.status === 200) {
        resetSelectedConversation();
        fetchConversations(1, 50);
        message.success("Reassigned");
        onClose();
      }
    } catch (error) {
      console.error(error);
      message.error("Reassign failed");
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Reassign Human Agent</DialogTitle>
      <DialogBody>
        <AgentAssignmentForm
          agents={agentsExcludingActiveAgent}
          onSubmit={onSubmit}
          buttons={{
            buttons: "2",
            primaryLabel: "Reassign",
            secondaryLabel: "Cancel",
          }}
          onClose={onClose}
        />
      </DialogBody>
    </Dialog>
  );
}

function AgentAssignmentForm(props) {
  const { agents, onSubmit, buttons, onClose } = props;
  const [loading, setLoading] = useState(false);

  const template = getTemplate(agents);

  return (
    <Form
      template={template}
      schema={schema}
      inputField={{ humanAgentId: "" }}
      buttonInfo={buttons}
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
