import React, { useState } from "react";
import { Divider } from "../../common/divider.jsx";
import { Button } from "../../common/button.jsx";
import { ArrowDownCircleIcon, FolderIcon } from "@heroicons/react/24/outline";

import { ConversationDownloader } from "../Downloads/index.js";

import { RenameWorkspace } from "../Workspaces/Rename/index.jsx";
import { WorkspaceActionsMenu } from "../Workspaces/ActionMenu/index.jsx";
import { DeleteWorkspace } from "../Workspaces/Delete/index.jsx";

import { getCurrentUser } from "../../services/portalAuthService.js";
import { useOutletContext } from "react-router-dom";

import {
  StatsCard,
  StatsCardLoading,
  StatsContainer,
} from "./components/Stats.jsx";

import colors from "tailwindcss/colors.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getLastSixMonthsFromCurrentMonth() {
  const currentDate = new Date();

  const lastSixMonths = Array.from({ length: 6 }, (_, i) => {
    const monthDate = new Date(currentDate);

    monthDate.setMonth(currentDate.getMonth() - i);

    if (monthDate.getDate() !== currentDate.getDate()) {
      monthDate.setDate(0);
    }

    const monthName = monthDate.toLocaleString("default", { month: "short" });
    const year = monthDate.getFullYear().toString().slice(-2);

    return `${monthName}'${year}`;
  }).reverse();

  return lastSixMonths;
}

function getLastSixOrPad(arr) {
  if (arr.length > 6) {
    return arr.slice(-6);
  } else if (arr.length === 6) {
    return arr;
  } else {
    return new Array(6 - arr.length).fill(0).concat(arr);
  }
}

function Calls(props) {
  const { current, history } = props;

  const inboundCalls = [
    ...(history?.inboundCalls ?? []),
    current?.inboundCalls,
  ];
  const outboundCalls = [
    ...(history?.outboundCalls ?? []),
    current?.outboundCalls,
  ];
  const webCalls = [...(history?.webCalls ?? []), current?.webCalls];

  const options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
          font: {
            size: 12,
          },
        },
        padding: 36,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {},
        border: {
          color: "gray",
          width: 0,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        border: {
          color: colors.gray[400],
          width: 1,
        },
        ticks: {
          font: {
            size: 12,
            lineHeight: 1.5,
          },
        },
      },
    },
  };

  const data = {
    labels: getLastSixMonthsFromCurrentMonth(),
    datasets: [
      {
        label: "Inbound",
        data: getLastSixOrPad(inboundCalls),
        backgroundColor: colors.blue[500],
      },
      {
        label: "Outbound",
        data: getLastSixOrPad(outboundCalls),
        backgroundColor: colors.blue[300],
      },
      {
        label: "WebCalls",
        data: getLastSixOrPad(webCalls),
        backgroundColor: colors.blue[200],
      },
    ],
  };

  return (
    <>
      <h2 className="text-md font-semibold">Calls Distribution</h2>
      <span className="text-sm text-gray-600">Past 6 months</span>
      <Bar options={options} data={data} />
    </>
  );
}

function Sms(props) {
  const { current, history } = props;

  const sms = [...(history?.questions ?? []), current.questions];

  const options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 24,
          font: {
            size: 12,
          },
        },
        padding: 36,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {},
        border: {
          color: "gray",
          width: 0,
        },
      },
      y: {
        display: false,
      },
    },
  };

  const data = {
    labels: getLastSixMonthsFromCurrentMonth(),
    datasets: [
      {
        label: "messages",
        data: getLastSixOrPad(sms),
        backgroundColor: colors.blue[500],
        tension: 0.3,
        borderColor: colors.blue[400],
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <h2 className="text-md font-semibold">Messages Sent</h2>
      <span className="text-sm text-gray-600">Past 6 months</span>
      <Line options={options} data={data} />
    </>
  );
}

function CallTime(props) {
  const { current, history } = props;

  const callTime = [...(history?.callTime ?? []), current.callTime];
  const sms = callTime.map((time) => Math.ceil(time / 60));

  const options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        position: "bottom", // You can change it to 'bottom', 'left', or 'right'
        labels: {
          padding: 24,
          font: {
            size: 12,
          }, // Adjust space between the chart and the legend
        },
        padding: 36, // Space between the chart area and the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {},
        border: {
          color: "gray",
          width: 0,
        },
      },
      y: {
        display: false,
      },
    },
  };

  const data = {
    labels: getLastSixMonthsFromCurrentMonth(),
    datasets: [
      {
        label: "Minutes",
        data: getLastSixOrPad(sms),
        backgroundColor: colors.blue[500],
        fill: true,
        tension: 0.3,
        borderColor: colors.blue[400],
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <h2 className="text-md font-semibold">Calls Usage (mins)</h2>
      <span className="text-sm text-gray-600">Past 6 months</span>
      <Line options={options} data={data} />
    </>
  );
}

export function Dashboard() {
  const { projects, activeProject, fetchProjects } = useOutletContext();

  const data =
    projects &&
    projects[activeProject] &&
    projects[activeProject].usage.current;

  const history =
    projects &&
    projects[activeProject] &&
    projects[activeProject]?.usage.history;

  const voiceSecLimit =
    projects &&
    projects[activeProject] &&
    projects[activeProject].voiceSecLimit;

  const [isOpen, setIsOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const currentUser = getCurrentUser();
  const isCurrentUserCompanyOwner = currentUser?.isCompanyOwner;

  return (
    <div className="">
      <main className=" flex flex-col p-8 ">
        <div className="gap-y-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <div className="size-4 text-gray-600">
                <FolderIcon />
              </div>
              <h3 className="font-semibold text-md">
                {projects[activeProject]?.name}
              </h3>
              {isCurrentUserCompanyOwner && (
                <WorkspaceActionsMenu
                  onRename={() => setIsRenameOpen(true)}
                  onDelete={() => setIsDeleteOpen(true)}
                />
              )}
            </div>
            <Button color="white" onClick={() => setIsOpen(true)}>
              <ArrowDownCircleIcon /> Download
            </Button>
          </div>
          {data && (
            <div className="grid grid-cols-3 gap-x-8 mt-4 mb-8">
              <div className="rounded-lg border p-8">
                <Calls current={data} history={history} />
              </div>

              <div className="rounded-lg border p-8">
                <CallTime current={data} history={history} />
              </div>
              <div className="rounded-lg border p-8">
                <Sms current={data} history={history} />
              </div>
            </div>
          )}
          {data ? (
            <StatsContainer>
              <StatsCard
                title="Calls (credits)"
                total={Math.ceil(voiceSecLimit / 60)}
                used={Math.ceil(data.callTime / 60)}
                calculateAvailable={(total, used) => total - used}
                calculateWidth={(total, used) =>
                  Math.floor((used / total) * 100)
                }
                unit="mins"
              />
            </StatsContainer>
          ) : (
            <StatsContainer>
              <StatsCardLoading />
            </StatsContainer>
          )}
        </div>
      </main>
      {isOpen && (
        <ConversationDownloader
          projectId={projects[activeProject]?._id}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      {isRenameOpen && (
        <RenameWorkspace
          isOpen={isRenameOpen}
          onClose={() => setIsRenameOpen(false)}
          project={projects[activeProject]}
          fetchProjects={fetchProjects}
        />
      )}
      {isDeleteOpen && (
        <DeleteWorkspace
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          project={projects[activeProject]}
          fetchProjects={fetchProjects}
        />
      )}
    </div>
  );
}
