import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
// import App from './App';
import { WebcallProvider } from "./V2.0/Context/WebcallContext";

import App from "./V2.0/App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Router>
    <WebcallProvider>
      <App />
    </WebcallProvider>
  </Router>
  // </React.StrictMode>
);
