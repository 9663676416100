import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import * as authService from "V2.0/services/portalAuthService";

function ResetPassword(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const otp = queryParams.get("otp") || "";
  const email = queryParams.get("email") || "";

  const [inputField, setInputField] = useState({
    otp: otp,
    email: email,
    newPassword: ""
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage] = useState({});

  const template = [
    {
      name: "newPassword",
      label: "New Password",
      type: "password"
    }
  ];

  const buttonInfo = {
    label: "Reset Password",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    otp: Joi.string().alphanum().required().label("OTP"),
    email: Joi.string().email().required().label("Email"),
    newPassword: Joi.string().min(8).required().label("New Password"),
  };

  const handleReset = async (data) => {
    try {
      const response = await authService.resetPassword(data);
      if (response.status === 200) {
        message.success("Password reset success");
        localStorage.removeItem("token");
        navigate("/login", { replace: true });
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  return (
    <div>
      <div className="bg-slate-50 flex items-center justify-center h-screen w-screen">
        <div className="relative w-[500px] rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] p-20">
          <div className="space-y-8">
            <Form
              formTitle={"Reset Password"}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleReset}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;